const menuList = [
  {
    url: "/dashboard",
    label: "Dashboard",
    icon: "ti-home",
    class_name: "has-submenu",
  },
  {
    url: "/transactions",
    label: " User Transactions",
    icon: "ti-package",
    class_name: "has-submenu",
  },
  {
    url: "/#",
    label: "Data",
    icon: "ti-signal  ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/data-servers", label: "Data Servers" },
          { url: "/sell-data", label: "Sell Data" },
          { url: "/data-plans", label: "Data Plans" },
          { url: "/mtn-sme-settings", label: "MTN SME Settings" },
          {
            url: "/data-wallet-subscriptions",
            label: "Data Wallet Transactions",
          },
        ],
      },
    ],
  },
  {
    url: "/#",
    label: "Airtime",
    icon: "ti-mobile ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/sell-airtime", label: "Sell Airtime" },
          { url: "/airtime-plans", label: "Airtime Plans" },
          {
            url: "/airtime-wallet-subscriptions",
            label: "Airtime Wallet Transactions",
          },
        ],
      },
    ],
  },
  {
    url: "/#",
    label: "Cable",
    icon: " ti-video-camera",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [{ url: "/cable-plans", label: "Cable TV Plans" }],
      },
    ],
  },

  {
    url: "/#",
    label: "Electric",
    icon: "ti-bolt ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [{ url: "/electric-plans", label: "Electric Plans" }],
      },
    ],
  },

  {
    url: "/#",
    label: "Business",
    icon: "ti-briefcase ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/business-transactions", label: "Business Transactions" },
          { url: "/withdrawals", label: "Withdrawals" },
          { url: "/withdraw-fund", label: "Withdraw Fund" },
          { url: "/customers", label: "My Customers" },
          { url: "/customers-transactions", label: "Customers' Transactions" },
          { url: "/business-profile", label: "Business Profile" },
          { url: "/app-settings", label: "Other App Settings" },
          { url: "/faq", label: "FAQs" },
          { url: "/metadata", label: "Website Metadata" },
          { url: "/html-snippet", label: "Additional HTML Snippet" },
          { url: "/ad-images", label: "Ad Images" },
        ],
      },
    ],
  },
  {
    url: "/#",
    label: "Staff",
    icon: "ti-heart ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/staff", label: "My Staff" },
          { url: "/add-staff", label: "Add Staff" },
        ],
      },
    ],
  },
  {
    url: "/#",
    label: "Account",
    icon: "ti-user ",
    is_mega: true,
    class_name: "has-submenu ml-1",
    children: [
      {
        url: "",
        label: "",
        children: [
          { url: "/profile", label: "My Profile" },
          { url: "/signout", label: "Sign Out" },
        ],
      },
    ],
  },
];

export default menuList;
