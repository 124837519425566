import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
   TempDataContext,
   FormDataContext,
   BusinessDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

  document.title = "Cable Plans-DataNow";
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  
  const planSelect = (cablePlan) => {
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'plan_id', value: cablePlan.id },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'status', value: cablePlan.status },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'name', value: cablePlan.name },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'atm_price', value: cablePlan.atm_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'wallet_price', value: cablePlan.wallet_price },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'message', value: cablePlan.message },});
    formDataDispatch({type: "SET_FORM_DATA", data: { name: 'commission', value: cablePlan.commission },});
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:true },
    });  }

 
   
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    }); 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("plan_id", String(formData.plan_id));
    urlencoded.append("name", String(formData.name));
    urlencoded.append("status", String(formData.status));
    urlencoded.append("atm_price", String(formData.atm_price));
    urlencoded.append("wallet_price", String(formData.wallet_price));
    urlencoded.append("message", String(formData.message));
    urlencoded.append("commission", String(formData.commission));
   
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "cable_settings",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "cablePlans", value:tempData.cablePlans.map((cablePlan) => {
            if(data.data.id*1===cablePlan.id*1) 
            return {...cablePlan,'message':data.data.m,
            'atm_price':data.data.a
            ,'wallet_price':data.data.w
            ,'name':data.data.n
             ,'commission':data.data.c
            ,'status':data.data.s, ...data.data}
            return cablePlan;
           } 
         )}
          });

        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'isModalOpen', value: true },
        });
        
              } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'isModalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
      };

   
  
  useEffect(() => {
    if(!tempData.cablePlansLoaded ) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'cablePlansLoaded', value: true },
      });
    }
  }, []);
    
  const filterTv =(e)=>{
    var cablePlan=tempData.cablePlansMaster;
   if(e.target.value)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.tv=== e.target.value)
    if(formData.filterStatus)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.status*1 === formData.filterStatus*1)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "cablePlans", 
      value: cablePlan
    }});
  }

  
  const filterStatus =(e)=>{
    var cablePlan=tempData.cablePlansMaster;
    if(e.target.value)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.status*1 === e.target.value*1)
    if(formData.filterTv)
    cablePlan=cablePlan.filter(cablePlan => cablePlan.tv=== formData.filterTv)
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "cablePlans", 
      value: cablePlan
    }});
  }

    
  const CablePlan = (props) => {

    return (
      <React.Fragment >
       <tr>
       <td   > 
      {props.cablePlan.status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.cablePlan.status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   > 
      {businessData.merchant_settings['cable_master']*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {businessData.merchant_settings['cable_master']*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   > 
      {props.cablePlan.master_status*1 === 0 && (<label className="badge   badge-success">
        Active</label>) }
        {props.cablePlan.master_status*1 === 1 && (<label className="badge  badge-danger">
        Disabled</label>) }
             </td>
             <td   >{props.cablePlan.tv}</td>
             <td   >{props.cablePlan.name}</td>
              <td  >{props.cablePlan.master_name}</td>
             <td  >₦{formatCurrency(props.cablePlan.atm_price)}</td>
             <td  >₦{formatCurrency(props.cablePlan.wallet_price)}</td>
             <td  >₦{formatCurrency(props.cablePlan.price)}</td>
             <td  >{formatCurrency(props.cablePlan.business_commission_fee)}%</td>
             <td  >{formatCurrency(props.cablePlan.commission)}%</td>
             <td   >{props.cablePlan.message}</td>
             <td   >{props.cablePlan.master_message}</td>
              <td className="text-primary" style={{cursor:"pointer"}} onClick={()=>{planSelect(props.cablePlan)}} >Edit</td>
            </tr>
              </React.Fragment>
              );
            };
        

            const fetchDataPlans = () => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving cable plans...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
                 var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "cable_settings" ,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.cable_plans);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "cablePlans", value: objectValues },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "cablePlansMaster", value: objectValues },
                    });
                  
                  } else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                
                else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <Row className="justify-content-center align-content-center ">
                 <Col xl="11" ml="11" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Cable Plans</b></p>
                <hr className="border-primary" />
                </div>
               
                 <Row>
                  <Col md="3" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.filterTv} name="filterTv" onChange={(e)=>{formOnChange(e); filterTv(e)} }>
                  <option value="">All TVs</option>
                  <option value="DSTV">DSTV</option>
                  <option value="GOTV">GOTV</option>
                  <option value="STARTIMES">STARTIMES</option>
                  </select>
                  </Col>

                      <Col md="2" xs="6" className="form-group mb-2">
                          <select className="form-control" value={formData.filterStatus} name="filterStatus"  onChange={(e)=>{formOnChange(e); filterStatus(e)} }>
                          <option value="">All Statuses</option>
                          <option value={0}>Active</option>
                          <option value={1}>Disabled</option>
                         </select>
                        </Col>
                  </Row>
                   
                  {tempData.cablePlans && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing  {tempData.cablePlans.length} Data Plans</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                      <th data-priority="1">Status</th>
                      <th data-priority="1">App Cable Status</th>
                      <th data-priority="1">Server Cable Status</th>
                      <th data-priority="1">Tv</th>
                      <th data-priority="1">Name</th>
                      <th data-priority="1">Default Name</th>
                      <th data-priority="1">ATM Price</th>
                      <th data-priority="1">Wallet Price</th>
                      <th data-priority="1">Price</th>
                      <th data-priority="1">Total Commission</th>
                      <th data-priority="1">User Commission</th>
                      <th data-priority="1">Message</th>
                      <th data-priority="1">Default Message</th>
 
                      </tr>
                      </thead>
                      <tbody>
                      { tempData.cablePlans.map((cablePlan) => {
                        return <CablePlan key={cablePlan.id} cablePlan={cablePlan} />;
                      }) }
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          {tempData.cablePlans && ( <Modal isOpen={tempData.isModalOpen}  >
      {/* toggle={modalOpen} */}
        <ModalBody >
        <div className="mb-2 text-dark">
        <button type="button" className="close"
            onClick={() => {  tempDataDispatch({
              type: "SET_FORM_DATA",
              data: { name: "isModalOpen", value:false },
            });}} >
            <span aria-hidden="true">&times;</span>
          </button>
                  <p className="text-uppercase text-left mb-0 " ><b>Edit Cable Plan</b></p>
                 
                  <hr className="border-primary" />
                 
                 


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >

<p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Plan Status</b>
                </p>
                <select required className="form-control mb-2" value={formData.status} name="status" onChange={formOnChange}>
                <option value="0">Active</option>
                  <option value="1">Disabled</option>
                  </select>

                 <AvField
                  name="name"
                  label="name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter plan name"
                  type="text"
                 />
 
                                     
<AvField
      label="User Commission less than or equal to total Commission"
      name="commission"
      value={formData.commission}
      onChange={(e) => { formOnChange(e) }}
      placeholder="User Commission "
      type="text"
      errorMessage="Enter valid amount"
     required
      />

<AvField
      label="Wallet Price"
      name="wallet_price"
      value={formData.wallet_price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="Wallet Price"
      type="number"
      errorMessage="Enter valid amount"
      validate={{
        required: { value: true },
        pattern: {
          value: "^[0-9]+$",
          errorMessage: "Only valid amount",
        },
      }}
      />
      
 <AvField
      label="ATM Price"
      name="atm_price"
      value={formData.atm_price}
      onChange={(e) => { formOnChange(e) }}
      placeholder="ATM Price"
                  type="number"
      errorMessage="Enter valid amount"
      validate={{
        required: { value: true },
        pattern: {
          value: "^[0-9]+$",
          errorMessage: "Only valid amount",
        },
      }}
      />


      
<AvField
                  name="message"
                  label="Plan Message"
                  value={formData.message}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Plan message"
                  type="text"
                 />
  
     
                

                <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>
                 </div>
        </ModalBody>
      </Modal>)
      }
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        