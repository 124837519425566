import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  BusinessDataContext,
  ModalContext,
  FormContext,
  TempDataContext,FormDataContext
} from "../../contexts/Contexts";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
   const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
   const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Profile-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions();
  };
 
  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Creating Staff...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("email", String(formData.email));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("password", String(formData.password));
    urlencoded.append("clearance_level", String(formData.clearance_level));
   
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "business_staff",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
                formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
         {/* <ResponseModal isOpen={true}/> */}
        <Row className="justify-content-center align-content-center ">
          <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                  >
                    <b>Add Staff</b>
                  </p>
                  <hr className="border-primary" />
                </div>


                <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >
                 <AvField
                  name="name"
                  label="name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter staff name"
                  type="text"
                  required
                />
 
                <AvField
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Email"
                  type="email"
                  required
                />

<AvField
                  name="phone_number"
                  label="Phone Number"
                  value={formData.phone_number}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter staff phone number"
                  type="number"
                  required
                  validate={{
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Enter valid phone number",
                    },
                  }}
                />
     
                <p
                  className="  text-left mb-0 mt-2 "
                >
                  <b>Clearance Level</b>
                </p>
                <select required className="form-control mb-2" value={formData.clearance_level} name="clearance_level" onChange={formOnChange}>
                <option  value="">--select clearance--</option>
                <option value="1">Staff</option>
                  <option value="0">Admin</option>
                  </select>
     
                  <AvField
                  name="password"
                  label="Password"
                  value={formData.password}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter a password for Staff (Min 6 characters)"
                  type="text"
                  required
                 />

                <Button
                  className="btn-primary w-md waves-effect waves-light mb-5 "
                  type="submit"
                >
                  Add Staff
                </Button>
              </AvForm>
            </div>
 
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
     
    </React.Fragment>
  );
};

export default withRouter(Payments);
