import React, { useState, useContext } from "react";
import { Alert, Button, Col, Row, Card } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    ModalContext,
    TempDataContext,
    BusinessDataContext,
} from "../../contexts/Contexts";
import logo from '../../images/logo-sm.png'
import wave from '../../images/wave-white.svg'
import { formatCurrency } from "../../helpers/utils";

const SignIn = (props) => {

    const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
    const { loaderDispatch } = useContext(ModalContext);
     const {   tempData, tempDataDispatch } = useContext(TempDataContext);
    document.title='Business Registration-DataNow';

     
   (businessData && businessData.business) ? 
  ( businessData.business.business_payment_status === 0 && props.history.push("/")) : props.history.push("/");
     
    const handleSubmit = (e) => {
        e.persist();
        e.preventDefault();
        loaderDispatch({
            type: "SET_LOADER",
            data: { text: "Connecting to server...", isLoading: true },
        });
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + businessData.token);
          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            
        };
        fetch(
            localStorage.getItem('apiURL')+"business_registration",
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
                const data = JSON.parse(response);
                console.log(data);
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
                if (data.status === "success") {
                    window.location = data.data.payment_url
                    }
                    else if (data.message === "Token Expired"){
                        props.history.push('/signout');    
                    }
                     else {
                    tempDataDispatch({
                        type: "SET_ERROR",
                        data: data.message,
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                });
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
            });
        };
       

   return (<React.Fragment>
    {!businessData.business ? 
  (<div></div>)
  :
  (
            <div style={{position:'relative'}}>       
             <img className=" mt-0" src={wave} style={{width:'100%',bottom:'0',position:'absolute'}} alt=""/>
             
             <Row className="bg-primary  align-content-center justify-content-center" style={{height:'100vh'}} >
               
                  <Col md="6" className=" align-self-center text-light text-center">
                      <h2>Complete your registration!</h2>
                      <br></br>
        <h3>Welcome, {businessData.merchant.name}! Kindly make a payment of ₦{formatCurrency(businessData.admin_settings.business_registration_payment)} to 
        complete your registration and gain access to {businessData.business.name} dashboard</h3> 
        <Button
            color="light"
            className="text-dark w-md waves-effect waves-light"
            type="submit"
            onClick={handleSubmit}
            >
            <h6>Pay ₦{formatCurrency(businessData.admin_settings.business_registration_payment)}</h6>
            </Button>
            <div className="m-3">
            <Link to="signout" className="m-3 text-white">
                   Sign Out 
                </Link>
            </div>
         
            </Col>
             </Row>
             </div>) }
             
             </React.Fragment>
                 );
        };
        
        export default withRouter(SignIn);
        