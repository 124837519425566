import React, {  useContext } from "react";
import { Alert, Button, Col, Row, Card } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
    ModalContext,
   TempDataContext,
   BusinessDataContext
  } from "../../contexts/Contexts";
import logo from '../../images/logo-sm.png'


const ForgotPassword = (props) => {
    const { loaderDispatch } = useContext(ModalContext);
     const { tempData, tempDataDispatch } = useContext(TempDataContext);
     const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  

    document.title='Forgot Password-DataNow';
    businessData.merchant && props.history.push("/dashboard");
    
    const formOnChange = (e) => {
        tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: e.target.name, value: e.target.value },
        });
    };
    const handleSubmit = (e) => {
        e.persist();
        e.preventDefault();
        loaderDispatch({
            type: "SET_LOADER",
            data: { text: "submitting request...", isLoading: true },
        });
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");
        var urlencoded = new URLSearchParams();
        urlencoded.append("identifier", String(tempData.identifier));
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
           
        };
        fetch(
            localStorage.getItem('apiURL')+ "forgot_password",
            requestOptions
            )
            .then((response) => (response = response.text()))
            .then((response) => {
                const data = JSON.parse(response);
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
                tempDataDispatch({
                    type: "SET_FORM_DATA",
                    data: { name: 'success', value:data.message},
                });
                    props.history.push("/ResetPassword");
            })
            .catch((error) => {
                console.log("error", error);
                tempDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                });
                loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                });
            });
        };
        
        return (
            <React.Fragment>
           
            <div className="wrapper-page mt-4">
            <Card className="overflow-hidden account-card mx-3 shadow">
            <div className=" p-4 text-white text-center position-relative bg-primary" >
        <h4 className="font-20 m-b-5 mb-4">Forgot Password</h4>
            <Link to="/" className="logo logo-admin">
            <img src={logo} height="36" alt="logo" />
            </Link>
            </div>
            <div className="account-card-content">
            {tempData.error && <Alert color="success">{tempData.error}</Alert>}
            <AvForm
            className="form-horizontal m-t-30"
            onValidSubmit={handleSubmit}
            >
            <AvField
            name="identifier"
            label="Email or Phone Number"
            value={tempData.identifier}
            onChange={(e) => {
                formOnChange(e);
            }}
            placeholder="Enter Email or Phone Number"
            type="text"
            required
            />
          
            
            <Row className="form-group m-t-20">
            <Col sm="6"></Col>
            <Col sm="6" className="text-right">
            <Button
    color="primary"
className="w-md waves-effect waves-light"
            type="submit"
            >
            Reset Password
            </Button>
            </Col>
            </Row>
          
            </AvForm>
            </div>
            </Card>
            <div className="m-t-40 text-center">
            <p>
            Remember your password ?
            <Link to="/signin" className="font-500 text-primary"   >
            SignIn now
            </Link>
            </p>
            </div>
            </div>
            </React.Fragment>
            );
        };
        
        export default withRouter(ForgotPassword);
        