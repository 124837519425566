import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  ModalContext,
 TempDataContext,
 BusinessDataContext,FormDataContext
} from "../../contexts/Contexts";
 
const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);


  document.title = "Profile-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'modalOpen', value: false },
  });
    fetchTransactions();
  };

  useEffect(() => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "name", value: businessData.merchant.name },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "email", value: businessData.merchant.email },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "phone_number", value: businessData.merchant.phone_number },
    });
  }, []);

  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Saving Changes...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("email", String(formData.email));
    urlencoded.append("phone_number", String(formData.phone_number));
    formData.password &&
      urlencoded.append("password", String(formData.password));
      formData.cpassword &&
      urlencoded.append("cpassword", String(formData.cpassword));
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
     
      body: urlencoded,
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'responseModal', value: {isOpen:true, text :data.message} },
        });
          businessDataDispatch({ type: 'STORE_APP_DATA', businessData:{...businessData,merchant:data.data}})
        }
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
       else  if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
        
              } else {
                formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: 'modalOpen', value: true },
        });
         }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
         {/* <ResponseModal isOpen={true}/> */}
        <Row className="justify-content-center align-content-center ">
          <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p
                    className="text-uppercase text-left mb-0 "
                    
                  >
                    <b>Profile</b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <div className="mb-2 text-dark">
                  <Row style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>NAME</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{businessData.merchant.name}</p>
                    </Col>
                  </Row>
                 <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>EMAIL</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{businessData.merchant.email}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>PHONE NUMBER</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{businessData.merchant.phone_number}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3" style={{ fontSize: 12 }}>
                    <Col xs="5">
                      <b>CLEARANCE LEVEL</b>
                    </Col>
                    <Col xs="7">
                      <p className="m-0 p-0">{businessData.merchant.clearance_level===0? "Admin" : "Staff"}</p>
                    </Col>
                  </Row>
                </div>

                <hr />
               <div className="text-center"> <p
                  onClick={() => {
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: 'modalOpen', value: true },
                  });                  }}
                  style={{ cursor: "pointer" }}
                  className="text-uppercase   mb-2 text-primary "
                >
                  <b>Edit Account</b>
                </p>
                <Link to="signout">
                  <p className="text-uppercase   text-danger">
                    <b>Sign Out</b>
                  </p> 
                </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    
    {tempData.modalOpen && (
        <Modal isOpen={true}>
        <ModalBody>
          <div className="mb-2 text-dark">
            <button
              type="button"
              className="close"
              onClick={() => {
                tempDataDispatch({
                  type: "SET_FORM_DATA",
                  data: { name: 'modalOpen', value: false },
              });
                }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p
              className="text-uppercase text-left mb-0 text-primary "
               
            >
              <b>Edit Profile</b>
            </p>
            <hr className="border-primary" />
            <div className="account-card-content">
              {formData.error && <Alert color="danger">{formData.error}</Alert>}
              <AvForm
                className="form-horizontal m-t-30"
                onValidSubmit={handleSubmit}
              >
                <AvField
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter Email"
                  type="email"
                  required
                />

                <AvField
                  name="phone_number"
                  label="Phone Number"
                  value={formData.phone_number}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter your phone number"
                  type="number"
                  required
                  validate={{
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Enter valid phone number",
                    },
                  }}
                />

                <AvField
                  name="name"
                  label="name"
                  value={formData.name}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter your name"
                  type="text"
                  required
                />
 
                <p
                  className="text-uppercase text-left mb-0 mt-2 small"
                >
                  <b>Change Password</b>
                </p>
                <hr
                  className="mt-0 border-primary"
                />

                <AvField
                  name="cpassword"
                  label="Enter Current Password to begin"
                  value={formData.cpassword}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter current Password"
                  type="password"
                />
              {formData.cpassword && (
                <React.Fragment>
                <AvField
                  name="password"
                  label="Enter new Password"
                  value={formData.password}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Enter new Password"
                  type="password"
                  required="true"
                />

                <AvField
                  name="password1"
                  type="password"
                  placeholder="Re-type new password"
                  errorMessage="Passwords do not match"
                  validate={{
                    required: { value: true  },
                    match: { value: "password" },
                  }}
                />
                </React.Fragment>
                )}

                <Button
                 
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Save Changes
                </Button>
              </AvForm>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )}
    </React.Fragment>
  );
};

export default withRouter(Payments);
