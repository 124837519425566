import React, { useContext, useEffect ,useState} from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button ,Table, Label} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Badge, Spinner, Pagination, PaginationItem, PaginationLink, Modal,ModalBody, UncontrolledPopover, PopoverHeader, PopoverBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
   ModalContext,
  TempDataContext,
  BusinessDataContext,FormDataContext
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
   const { businessData, businessDataDispatch } = useContext(BusinessDataContext);  
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = 'Staff-DataNow';
  
  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };
   
  const toggleFilter = (e) => {
    e.preventDefault();
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'showFilter', value: !tempData.showFilter },
    });
  };
  
  const navigateToPage = (page) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: 'staffs', value: false},
    });
    fetchStaff(page)
  }
  
  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchStaff(1);
  };
  
  useEffect(() => {
    if(!tempData.staffLoaded ) {
      fetchStaff(1);
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: 'staffLoaded', value: true },
      });
    }
  }, []);
    
  
const ConfirmationModal = (props) => {
  const confirm=()=>{
      tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "staffs", value:tempData.staffs.map((staff) => {
       if(staff.merchant_id===tempData.activeID) staff.updatingClearance=true;
       return staff;
      } 
    )},
      });

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    });
 

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("clearance_level", String(tempData.staff.clearance_level*1===1?0:1));
    urlencoded.append("merchant_id", String(tempData.activeID));
     var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: urlencoded,
       
    };
   
    fetch(
      localStorage.getItem("apiURL") + "business_staff" ,
      requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "staffs", value:tempData.staffs.map((staff) => {
             if(staff.merchant_id===tempData.activeID) return data.data
             return staff;
            } 
          )},
            });
 
        }   
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const decline=()=>{
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isModalOpen", value:false },
    });
  }
  return (
         <Modal  
        isOpen={tempData.isModalOpen}   backdrop="static" keyboard={false} centered={true}>
         <p className="text-danger text-center" >
        <i className="mdi mdi-alert-circle-outline  " style={{fontSize:120}}></i>
     </p>
     <p className="text-center" >Confirm Change. Are you sure?</p>
    <p className="text-center m-0">
       <button className="btn btn-danger m-2" onClick={confirm}>Yes</button>
     <button  className="btn btn-info m-2" onClick={decline}>No</button>
     </p>
        </Modal>
     );
  }


  
const DeleteModal = (props) => {
  const confirm=()=>{
      tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "staffs", value:tempData.staffs.map((staff) => {
       if(staff.merchant_id===tempData.deleteID) staff.deletingStaff=true;
       return staff;
      } 
    )},
      });

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isDeleteModalOpen", value:false },
    });
 

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("merchant_id", String(tempData.deleteID));
     var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: urlencoded,
       
    };
   
    fetch(
      localStorage.getItem("apiURL") + "business_staff",
      requestOptions
      )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "staffs", value:tempData.staffs.filter(staff => 
             staff.merchant_id!==tempData.deleteID
          )},
            });
 
        }   
        else if (data.message === "Token Expired"){
          props.history.push('/signout');    
      }
      
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const decline=()=>{
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isDeleteModalOpen", value:false },
    });
  }
  return (
         <Modal  
        isOpen={tempData.isDeleteModalOpen}   backdrop="static" keyboard={false} centered={true}>
         <p className="text-danger text-center" >
        <i className="mdi mdi-alert-circle-outline  " style={{fontSize:120}}></i>
     </p>
     <p className="text-center" >Confirm Deletion. Are you sure?</p>
    <p className="text-center m-0">
       <button className="btn btn-danger m-2" onClick={confirm}>Yes</button>
     <button  className="btn btn-info m-2" onClick={decline}>No</button>
     </p>
        </Modal>
     );
  }
  
  const Staff = (props) => {

     const changeClearance=()=>{
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "activeID", value:props.staff.merchant_id },
      });
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "staff", value:props.staff },
      });
    tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "isModalOpen", value:true },
      });
    }

    const deleteStaff=()=>{
      tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "deleteID", value:props.staff.merchant_id },
    });
  tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "isDeleteModalOpen", value:true },
    });
  }


    return (
      <React.Fragment >
       <tr  style={{ cursor:'pointer' }}>
       <td  >{props.staff.name}</td>
       <td  >{props.staff.email}</td>
              <td  >{props.staff.phone_number}</td>
              <td  >{props.staff.clearance_level*1===1?"Staff" :"Admin"}</td>
              <td  >{props.staff.created_at}</td>
              <td  >{props.staff.merchant_id}</td>
             <td >  
       {props.staff.updatingClearance ? "updating clearance..." :
        (<Label style={{cursor:'pointer'}} onClick={()=>{changeClearance()}} className="text-primary">{props.staff.clearance_level*1===1 ?"Change to Admin":  "Change to Staff" }</Label>)} 
       </td>
       
       <td >  
       {props.staff.deletingStaff ? "deleting staff..." :
        (<Label style={{cursor:'pointer'}} onClick={()=>{deleteStaff()}} className="text-danger">Delete</Label>)} 
       </td>
        </tr>
              </React.Fragment>
              );
            };
            
            const fetchStaff = (page) => {
              loaderDispatch({
                type: "SET_LOADER",
                data: { text: "Retrieving staff...", isLoading: true },
              });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + businessData.token);
              var parameter = "";
              parameter += formData.search ? '&search=' + formData.search : '';
            parameter += formData.pageSize ? '&size=' + formData.pageSize : '';
              parameter += '&page=' + page ;
              var requestOptions = {
                method: "GET",
                headers: myHeaders,
               
              };
              fetch(
                localStorage.getItem("apiURL") + "business_staff?" + parameter,
                requestOptions
                )
                .then((response) => (response = response.text()))
                .then((response) => {
                  const data = JSON.parse(response);
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                  console.log(data);
                  if (data.status === "success") {
                    const objectValues = Object.values(data.data.data);
                    console.log(objectValues);
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "staffs", value: objectValues },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "current_page", value: data.data.current_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "last_page", value: data.data.last_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "per_page", value: data.data.per_page },
                    });
                    tempDataDispatch({
                      type: "SET_FORM_DATA",
                      data: { name: "total", value: data.data.total },
                    });
                  }
                  else if (data.message === "Token Expired"){
                    props.history.push('/signout');    
                }
                 else if (data.errors) {
                    let errorString = "";
                    const objectValues = Object.values(data.errors);
                    objectValues.map((error) => {
                      errorString = errorString + error + ", ";
                    });
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: errorString,
                    });
                  } else {
                    formDataDispatch({
                      type: "SET_ERROR",
                      data: data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  formDataDispatch({
                    type: "SET_ERROR",
                    data: "unable to connect to server",
                  });
                  loaderDispatch({
                    type: "SET_LOADER",
                    data: { text: "", isLoading: false },
                  });
                });
              };
              
              
              return (
                <React.Fragment>
                <Container fluid  >
                <DeleteModal/>
                <ConfirmationModal/>
               <Row className="justify-content-center align-content-center ">
                 <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
                <Card className="mini-stat text-dark shadow">
                <CardBody>
                <div className="mb-2">
                <p className="text-uppercase text-left mb-0 "><b>Staff</b></p>
                <hr className="border-primary" />
                </div>
                <Link  onClick={toggleFilter} className="mb-0 pb-0">
                {(!tempData.showFilter) ? <p className="text-uppercase text-left mb-0 small"><i className="mdi mdi-filter "></i>Show Filters </p> :
                <p className="text-uppercase text-left mb-0 small"><i className="mdi mdi-filter-remove "></i> Hide Filters</p>}
                </Link>
                <AvForm className="mt-2" role="search" onValidSubmit={handleSubmit}>
                <Row>
                {tempData.showFilter && (
                  <React.Fragment>
                   <Col md="2" xs="6" className="form-group mb-2">
                  <select className="form-control" value={formData.pageSize} name="pageSize" onChange={formOnChange}>
                  <option value="">15 per page</option>
                  <option value="30">30 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                  </select>
                  </Col>                
                  </React.Fragment>
                  )}
                  
                  <div className=" mb-0 ml-3 app-search ">
                  <input
                  type="text"
                  className="form-control bg-light text-dark shadow"
                  placeholder="Search.."
                  name="search"
                  value={formData.search}
                  onChange={formOnChange}
                  />
                  <button  
                  style={{marginTop:-12}}
                  type="submit"><i className="mdi mdi-magnify  mdi-24px text-primary " ></i>
                  </button>
                  </div>
                  </Row>
                  </AvForm>
                  
                  {tempData.staffs && (
                    <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">Showing ( 
                      {tempData.per_page*tempData.current_page- tempData.per_page+1 } to {tempData.current_page*tempData.per_page-tempData.per_page+tempData.staffs.length} ) of {tempData.total} Staffs</p>
                      
                      <div className="table-rep-plugin">
                      <Table responsive striped hover>
                      <thead>
                      
                      <tr>
                       <th data-priority="1">Name.</th>
                      <th data-priority="3">Email</th>
                      <th data-priority="3">Phone Number</th>
                      <th data-priority="1">Clearance</th>
                      <th data-priority="3">Date Created</th>
                      <th data-priority="3">Staff ID</th>
                      <th data-priority="6"></th>
                      <th data-priority="6"></th>
                      </tr>
                      </thead>
                      <tbody>
                      
                      {tempData.staffs.map((staff) => {
                        return <Staff key={staff.id} staff={staff} />;
                      })}
                      </tbody>
                      </Table>
                      </div>
                      
                      </React.Fragment>
                      )
                    }
                    
                    
                    {tempData.staffs &&
                      (<React.Fragment>
                        <hr className="mt-0" />
                        <p className="text-uppercase text-left mb-0 small">Showing page {tempData.current_page} of {tempData.last_page}</p>
                        <Row className="ml-1 mt-2 " >
                        <Pagination className="pagination-sm">
                        <PaginationItem disabled={tempData.current_page > 1 ? false : true} >
                        <PaginationLink first onClick={() => { navigateToPage(tempData.current_page - 1) }} />
                        </PaginationItem >
                        {tempData.current_page - 5 > 0 && (
                          <PaginationItem onClick={() => { navigateToPage(tempData.current_page - 5) }} ><PaginationLink>{tempData.current_page - 5}</PaginationLink></PaginationItem>
                          )}
                          
                          <PaginationItem active><PaginationLink>{tempData.current_page}</PaginationLink></PaginationItem>
                          
                          {tempData.last_page >= tempData.current_page + 5 && (
                            <PaginationItem onClick={() => { navigateToPage(tempData.current_page + 5) }}><PaginationLink>{tempData.current_page + 5}</PaginationLink></PaginationItem>
                            )}
                            
                            <PaginationItem  disabled={tempData.current_page === tempData.last_page ? true : false}>
                            <PaginationLink onClick={() => { navigateToPage(tempData.current_page + 1) }} last />
                            </PaginationItem>
                            </Pagination>
                            </Row>
                            </React.Fragment>)
                          }
                          
                          </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          </Container>
                          </React.Fragment>
                          );
                        };
                        
                        export default withRouter(Payments);
                        